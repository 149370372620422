// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

// Animation Component
import { motion } from 'framer-motion';

function PromoAreaOne() {

    return (
        <Card className='flex xs:max-w-xs' sx={{ bgcolor: 'transparent' }}>
            <CardContent className='xs:px-1 xs:py-0 xs:max-w-xs'>
                <Box className='flex flex-auto flex-col items-center xs:max-w-xs'>
                    <Box className='flex flex-auto justify-center items-center flex-wrap xs:justify-center xs:items-center sm:justify-center sm:items-center mb-5 xs:mb-2 sm:mb-2 xs:pb-0' >
                        <Box className='flex flex-col justify-center items-center pr-5 xs:p-0 xs:mb-1' component={motion.div} initial={{ y: 100, opacity: 0 }} animate={{ y: 0 }} transition={{ delay: 1 }} whileInView={{ opacity: 1 }}>
                            <Typography className='text-left text-balance font-bold font-toyota xs:text-3xl xs:text-center md:text-center' sx={{ color: 'white', fontSize: '3.5rem' }}>
                                Save Big
                            </Typography>
                            <Typography className='text-left text-balance font-bold font-toyota xs:text-3xl xs:text-center md:text-center' sx={{ color: 'white', fontSize: '3.5rem' }}>
                                On Service!
                            </Typography>
                        </Box>
                        <Divider className='xs:hidden sm:hidden md:hidden' orientation="vertical" flexItem light={true} sx={{ bgcolor: 'white' }} component={motion.div} initial={{ y: 100, opacity: 0 }} animate={{ y: 0 }} transition={{ delay: 1.3 }} whileInView={{ opacity: 1 }} />
                        <Box className='flex flex-col justify-center items-center pl-5 xs:p-0' component={motion.div} initial={{ y: 100, opacity: 0 }} animate={{ y: 0 }} transition={{ delay: 1 }} whileInView={{ opacity: 1 }}>
                            <Typography className='text-left text-balance font-bold font-toyota xs:text-lg xs:text-center sm:text-center md:text-center' sx={{ color: 'white', fontSize: '2.75rem' }}>
                                Rev Up Your Savings This Black Friday
                            </Typography>
                            <Typography className='text-left text-balance font-bold font-toyota xs:text-lg xs:text-center sm:text-center md:text-center' sx={{ color: 'white', fontSize: '2.75rem' }}>
                                At A Valley Toyota Dealers Service Center
                            </Typography>
                        </Box>
                    </Box>
                    <Box className='flex flex-col justify-center items-center w-full xs:p-0' component={motion.div} initial={{ y: 100, opacity: 0 }} animate={{ y: 0 }} transition={{ delay: 1 }} whileInView={{ opacity: 1 }}>
                        <Button href='#map-form' variant="contained" disableElevation size='large' className='text-center font-toyota font-bold bg-toyota-red text-lg hover:bg-toyota-dark w-full py-5 xs:max-w-xs xs:mb-2 xs:text-base'>
                            Click Here To Find Your Nearest Service Center!
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

export default PromoAreaOne;