// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

// Animation Component
import { motion } from 'framer-motion';

function ActionCall() {

    return (
        <Card className='flex items-center flex-auto xs:flex-wrap-reverse xs:justify-center sm:flex-wrap md:flex-wrap md:justify-center mx-8 mt-8 mb-12 xs:mb-5' sx={{ bgcolor: 'transparent' }}>
            <CardMedia className='w-fit rounded-xl max-w-3xl xs:max-h-56 xs:max-w-xs sm:max-w-sm md:max-w-xl' component='img' image='https://email-mrktng-images.s3.amazonaws.com/images/landing-page/2024/July/Toyota-Tech.jpg' alt='Toyota Technician Working On A Toyota Vehicle' sx={{ objectFit: 'contain' }} />
            <CardContent className='p-0'>
                <Box className='flex flex-auto flex-col items-center content-center p-5' component={motion.div} animate={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: -50 }} transition={{ duration: 1 }} whileInView={{ opacity: 1 }}>
                    <Typography className='font-toyota text-center font-extrabold xs:text-4xl' variant='h2' component='h2' sx={{ color: 'white' }}>
                        Come Back
                    </Typography>
                    <Typography className='font-toyota text-center font-normal mb-3 xs:text-4xl xs:mb-6' variant='h2' component='h2' sx={{ color: 'white' }}>
                        To The Team That Knows Your Toyota Best
                    </Typography>
                    <Button href='#map-form' variant="contained" disableElevation size='large' className='text-center font-toyota font-bold bg-toyota-red text-lg hover:bg-toyota-dark w-full py-5 xs:max-w-xs xs:text-base'>
                        Click Here To Find Your Nearest Service Center!
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}

export default ActionCall;