// MUI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

// Animation Component
import { motion } from 'framer-motion';

function PromoAreaTwo() {

    return (
        <Card className='flex justify-center' sx={{ bgcolor: 'transparent' }}>
            <CardContent className='p-0'>
                <Box component={motion.div} initial={{ y: -1000, opacity: 0 }} animate={{ y: 0 }} transition={{ duration: 1, delay: 0.5 }} whileInView={{ opacity: 1 }}>
                    <CardMedia
                        className="rounded-lg shadow-lg xs:max-h-64 xs:max-w-xs sm:max-w-sm md:max-w-xl"
                        component='img'
                        image='https://email-mrktng-images.s3.us-east-1.amazonaws.com/images/landing-page/2024/November/Hero.jpg'
                        alt="Toyota Black Friday Promo"
                        sx={{ width: '1240px' }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default PromoAreaTwo;